/* App.css */
@font-face {
  font-family: 'Friendly Fire';
  src: local('Friendly Fire'), url(../public/fonts/Friendly_Fire.ttf);
}

@font-face {
  font-family: 'Kenyan Coffee';
  src: local('Kenyan Coffee'), url(../public/fonts/kenyan_coffee_rg.otf);
}

body {
  font-family: 'Kenyan Coffee', Arial, sans-serif;
  background-color: #2a1552;
  color: white;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  min-height: 100vh;
  text-align: center; 
}

h2, h3, p {
  user-select: none;
  font-family: 'Friendly Fire';
  letter-spacing: 0.07em;
  max-width: 90rem;
  text-transform: uppercase;
  text-align: center; 
  text-wrap: wrap;
}

p {
  margin: 0.5em;
}

h2 {
  font-size: 2.7em;
  padding: 10px;
  text-shadow: -0.1em -0.1em 0 #000, 0.1em -0.1em 0 #000, -0.1em 0.1em 0 #000,
    0.1em 0.1em 0 #000, 0 -0.1em 0 #000, 0.1em 0 0 #000, 0 0.1em 0 #000,
    -0.1em 0 0 #000;
}

h3 {
  font-size: 2.25em;
  padding: 15px;
  text-shadow: -0.1em -0.1em 0 #000, 0.1em -0.1em 0 #000, -0.1em 0.1em 0 #000,
    0.1em 0.1em 0 #000, 0 -0.1em 0 #000, 0.1em 0 0 #000, 0 0.1em 0 #000,
    -0.1em 0 0 #000;
}

.page {
  justify-content: center;
  flex-grow: 1; 
  display: flex;
  flex-direction: column;
  align-items: center; 
  text-align: center; 
}

button {
  margin: 2rem;
  cursor: pointer;
  font-family: 'Friendly Fire';
  font-size: 2rem;
  text-transform: uppercase;
  background-color: rgb(242, 175, 28);
  border: none;
  border-radius: 0.75rem;
  padding: 1rem 2rem;
  user-select: none;
  transition: border-color 0.01s, background-color 0.01s, color 0.01s, outline 0.01s;
}

button:hover {
  border-color: transparent;
  outline: solid white 0.1em;
}

button:active {
  border-color: transparent;
  background-color: white;
}

input[type='text'] {
  background: none;
  background-color: #160d2e;
  border: 0.25rem solid #f0eeda;
  border-radius: 0.75rem;
  color: inherit;
  font-family: 'Friendly Fire';
  font-size: 2rem;
  letter-spacing: 0.1em;
  padding: 0.5rem;
}

.menu {
  margin-top: 10em !important;
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-top: 1em; 
  border: solid rgb(68, 51, 154) 0;
  border-bottom: solid rgb(68, 51, 154) 0.2em;
  outline: solid black 0.2em;
  border-radius: 0.5rem;
  background-color: rgb(84, 64, 187);
}

.menu > * {
  flex: 1; 
  text-align: center; 
  margin: 0.5rem; 
}

.link {
  text-decoration: none;
  color: white !important;
  font-size: 1em !important;
  font-family: 'Friendly Fire';
}

.card {
  gap: 1em; 
  display: inline-flex;
  width: 150px;
  margin: 6px;
  text-align: center;
}

.plus {
  font-size: 2em;
}

span {
  text-shadow: -0.075em -0.075em 0 #000, 0.075em -0.075em 0 #000, -0.075em 0.075em 0 #000,
    0.075em 0.075em 0 #000, 0 -0.075em 0 #000, 0.075em 0 0 #000, 0 0.075em 0 #000,
    -0.075em 0 0 #000;
}

.combo {
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: center;
}

.card-content {
  position: relative; 
}

.card-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

p {
  font-size: 1.75em;
  text-shadow: -0.075em -0.075em 0 #000, 0.075em -0.075em 0 #000, -0.075em 0.075em 0 #000,
  0.075em 0.075em 0 #000, 0 -0.075em 0 #000, 0.075em 0 0 #000, 0 0.075em 0 #000,
  -0.075em 0 0 #000;
}

.cost-icon {
  position: absolute; 
  top: 0px;
  right: 1px; 
  max-width: 25px;
  height: auto;
  display: block;
}

.level-icon {
  position: absolute; 
  top: 2px; 
  right: 63px; 
  max-width: 23px;
  height: auto;
  display: block;
}

.deck-images,
.characterCard {
  padding: 15px;
}

.green-cost {
  color: green;
}

.yellow-cost {
  color: yellow;
}

.red-cost {
  color: red;
}

.blue-cost {
  color: blue;
}

.plus {
  font-size: 2rem; 
}

.potentialComboDesc {
  font-size: 1.5rem; 
}

@media (max-width: 768px) {
  h2 {
    font-size: 2em; 
    padding: 5px; 
  }

  h3 {
    font-size: 1.8em; 
    padding: 10px; 
  }

  p {
    font-size: 1.75em; 
  }

  button {
    font-size: 1.5rem; 
  }

  .menu {
    margin: 1em;
    text-wrap:wrap;
  }
}